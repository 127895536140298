import { Link, useLocation } from "react-router-dom";
import { UpdateType } from "../collections/Updates";

export default function RecentUpdates(props: { updates: UpdateType[] }) {
  const location = useLocation();
  return (
    <div>
      {props.updates.map((update, idx) => (
        <div className="my-3" key={idx}>
          <div className="flex">
            <Link to={`/update/${update.id}`} className="w-3/12">
              <img
                src={update.image}
                alt=""
                className={`${
                  location.pathname.includes("update/")
                    ? "xl:aspect-[5/4]"
                    : "xl:aspect-video"
                } rounded-lg object-cover aspect-square md:aspect-[4/3]`}
              />
            </Link>
            <div className="w-3/5 ml-1.5 flex flex-col justify-around md:ml-3 xl:ml-4">
              <div>
                <Link to={`/update/${update.id}`}>
                  <h2 className="text-xs leading-3 sm:text-base md:leading-5 xl:text-lg gold-hover">
                    {update.title}
                  </h2>
                </Link>
              </div>
              <div className="justify-between">
                <p className="text-xxs sans-serif mr-3 leading-3">
                  {update.date.toLocaleDateString("en-us", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  })}
                </p>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
