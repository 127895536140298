import RecentArticles from "../components/RecentArticles";
import { articles } from "../collections/Articles";

import MotionVariants from "../components/MotionVariants";
import { motion } from "framer-motion";

import FormatQuoteIcon from "@mui/icons-material/FormatQuote";

export default function Home() {
  return (
    <div className="wrapper">
      <div>
        <div className="mx-3 mt-8">
          <div></div>
          <h1>Helping Christian Gamers Grow in their Faith</h1>
        </div>
      </div>

      <div>
        <div className="mx-3 mt-4">
          <MotionVariants
            elements={[
              <h2 className="font-black">
                Christians are called to be{" "}
                <span className="text-gold font-black">holy</span>
              </h2>,
              <h2 className="font-black mb-2">
                . . . but how do we be holy with gaming?
              </h2>,
              <p className="serif mb-2">
                To be holy is to be "set apart." Being set apart means living
                with a new purpose. As Christians, we're called to glorify God
                in <span className="text-gold serif">everything</span> that we
                do. Developing a theology of gaming is to allow the gospel to
                transform your understanding of gaming.
              </p>,
              <div className="bg-white text-black p-3 pt-10 rounded-lg relative sm:p-5 sm:pt-10 lg:p-8 lg:pt-14">
                <span className="absolute text-xl left-1 top-1 sm:left-3 sm:top-3 lg:left-6 lg:top-6">
                  <FormatQuoteIcon fontSize="large" />
                </span>
                <p className="font-black sans-serif text-md my-1 lg:text-lg">
                  So, whether you eat or drink, or whatever you do, do all to
                  the glory of God.
                </p>
                <p className="font sans-serif text-[0.6rem] lg:text-sm xl:mt-2">
                  1 Corinthians 10:31 (English Standard Version)
                </p>
              </div>,
            ]}
            viewportAmount={0.5}
          />
        </div>
      </div>

      <div>
        <div className="mx-3 mt-4">
          <MotionVariants
            elements={[
              <h2 className="font-black">
                There's not much out there to help with this
              </h2>,
              <h2 className="font-black">. . . yet</h2>,
              <p className="serif mt-2">
                Our mission is to develop a free online course for gamers to
                develop a systematic, applicational, cross-cultural, and
                personal theology of gaming.
              </p>,
            ]}
          />
          <div className="mt-3">
            <MotionVariants
              elements={[
                <motion.div
                  whileHover={{ scale: 1.03 }}
                  className="bg-white h-36 sm:h-52 rounded-lg m-1 sm:m-2 p-3 w-36 sm:w-32 lg:w-40 xl:w-44"
                >
                  <h3 className="sans-serif font-bold">Unit 1</h3>
                  <p className="sans-serif font-semibold text-xs sm:text-sm">
                    What is Worldview?
                  </p>
                  <hr className="border-[1px] border-zinc-400 rounded-xl my-1" />
                  <p className="sans-serif leading-3 text-[0.5rem] sm:text-[0.6rem] lg:text-[0.75rem] sm:leading-4">
                    How do Christians understand the world?
                  </p>
                </motion.div>,
                <motion.div
                  whileHover={{ scale: 1.03 }}
                  className="bg-white h-36 sm:h-52 rounded-lg m-1 sm:m-2 p-3 w-36 sm:w-32 lg:w-40 xl:w-44"
                >
                  <h3 className="sans-serif font-bold">Unit 2</h3>
                  <p className="sans-serif font-semibold text-xs sm:text-sm">
                    Christianity and Gaming
                  </p>
                  <hr className="border-[1px] border-zinc-400 rounded-xl my-1" />
                  <p className="sans-serif leading-3 text-[0.5rem] sm:text-[0.6rem] lg:text-[0.75rem] sm:leading-4">
                    How does gaming fit into the Christian worldview?
                  </p>
                  <p className="sans-serif leading-3 text-[0.5rem] sm:text-[0.6rem] lg:text-[0.75rem] sm:leading-4">
                    How do we develop a theology of gaming?
                  </p>
                </motion.div>,
                <motion.div
                  whileHover={{ scale: 1.03 }}
                  className="bg-white h-36 sm:h-52 rounded-lg m-1 sm:m-2 p-3 w-36 sm:w-32 lg:w-40 xl:w-44"
                >
                  <h3 className="sans-serif font-bold">Unit 3</h3>
                  <p className="sans-serif font-semibold text-xs sm:text-sm">
                    Christian Gaming
                  </p>
                  <hr className="border-[1px] border-zinc-400 rounded-xl my-1" />
                  <p className="sans-serif leading-3 text-[0.5rem] sm:text-[0.6rem] lg:text-[0.75rem] sm:leading-4">
                    How do we apply a theology of gaming to our lives?
                  </p>
                </motion.div>,
                <motion.div
                  whileHover={{ scale: 1.03 }}
                  className="bg-white h-36 sm:h-52 rounded-lg m-1 sm:m-2 p-3 w-36 sm:w-32 lg:w-40 xl:w-44"
                >
                  <h3 className="sans-serif font-bold">Unit 4</h3>
                  <p className="sans-serif font-semibold text-xs sm:text-sm">
                    Gaming Addictions
                  </p>
                  <hr className="border-[1px] border-zinc-400 rounded-xl my-1" />
                  <p className="sans-serif leading-3 text-[0.5rem] sm:text-[0.6rem] lg:text-[0.75rem] sm:leading-4">
                    How should we view gaming addictions? How do people become
                    addicted and how can we become free from gaming addictions?
                  </p>
                </motion.div>,
                <motion.div
                  whileHover={{ scale: 1.03 }}
                  className="bg-white h-36 sm:h-52 rounded-lg m-1 sm:m-2 p-3 w-36 sm:w-32 lg:w-40 xl:w-44"
                >
                  <h3 className="sans-serif font-bold">Unit 5</h3>
                  <p className="sans-serif font-semibold text-xs sm:text-sm">
                    Christianity and Gaming Culture
                  </p>
                  <hr className="border-[1px] border-zinc-400 rounded-xl my-1" />
                  <p className="sans-serif leading-3 text-[0.5rem] sm:text-[0.6rem] lg:text-[0.75rem] sm:leading-4">
                    As Christians, how should we view the gaming community?
                    Should we participate or stay away from it?
                  </p>
                </motion.div>,
                <motion.div
                  whileHover={{ scale: 1.03 }}
                  className="h-36 sm:h-52 rounded-lg m-1 sm:m-2 p-3 w-36 sm:w-32 lg:w-40 xl:w-44 bg-gradient-to-b from-white from-50% to-black"
                >
                  <h3 className="sans-serif font-bold">Unit 6</h3>
                  <p className="sans-serif font-semibold text-xs sm:text-sm">
                    Work in Progress . . .
                  </p>
                  <hr className="border-[1px] border-zinc-400 rounded-xl my-1" />
                  <p className="sans-serif leading-3 text-[0.5rem] sm:text-[0.6rem] lg:text-[0.75rem] sm:leading-4">
                    This unit is still being worked on . . .
                  </p>
                </motion.div>,
              ]}
              styles={"flex text-black flex-wrap justify-around"}
              viewportAmount={"some"}
            />
          </div>
        </div>
      </div>

      <div>
        <div className="mx-3 mt-4">
          <MotionVariants
            elements={[
              <h2 className="font-black">
                We're still working on this though 😅😅😅
              </h2>,
              <div className="flex my-3">
                <div className="min-w-[0.5625rem] border-dotted border-y-0 border-r-[5px]">
                  <motion.div
                    className="relative"
                    animate={{
                      y: 8,
                    }}
                  >
                    <div className="h-4 w-4 rounded-full bg-gold absolute animate-ping"></div>
                    <div className="h-4 w-4 rounded-full bg-gold absolute"></div>
                  </motion.div>
                </div>
                <div className="ml-4">
                  <ul>
                    <MotionVariants
                      elements={[
                        <li>
                          <h3 className="font-semibold">Gather Support</h3>
                          <p className="sans-serif ml-3">↳ Prayer Support</p>
                          <p className="sans-serif ml-3">↳ Ministry Partners</p>
                        </li>,
                        <li className="opacity-40 mt-2">
                          <h3 className="font-semibold">
                            Conduct a Quantitative Survey
                          </h3>
                          <p className="sans-serif ml-3">↳ Create a Survey</p>
                          <p className="sans-serif ml-3">↳ Conduct Survey</p>
                          <p className="sans-serif ml-3">↳ Gather Results</p>
                          <p className="sans-serif ml-3">↳ Publish Results</p>
                        </li>,
                        <li className="opacity-40 mt-2">
                          <h3 className="font-semibold">
                            Develop an Online Course
                          </h3>
                          <p className="sans-serif ml-3">
                            ↳ Define Learning Goals
                          </p>
                          <p className="sans-serif ml-3">
                            ↳ Define Target Audience
                          </p>
                          <p className="sans-serif ml-3">↳ Create Content</p>
                          <p className="sans-serif ml-3">
                            ↳ Publish Course
                          </p>
                        </li>,
                      ]}
                      viewportAmount={0.5}
                    />
                  </ul>
                </div>
              </div>,
            ]}
            viewportAmount={0.25}
          />
        </div>
      </div>

      <div className="mt-3 mb-5 mx-3">
        <MotionVariants
          elements={[
            <h2 className="mt-2 font-black">
              Why don't you check out some articles in the meantime?
            </h2>,
            <p className="sans-serif mb-2">
              (These will eventually be made into the course content.)
            </p>,
            <RecentArticles articles={articles} />,
          ]}
          viewportAmount={0.25}
        />
      </div>
    </div>
  );
}
