import { Link, useLocation } from "react-router-dom";
import { ArticleType } from "../collections/Articles";

export default function RecentArticles(props: { articles: ArticleType[] }) {
  const location = useLocation();
  return (
    <div>
      {props.articles.map((article, idx) => (
        <div className="my-3" key={idx}>
          <div className="flex">
            <Link to={`/article/${article.id}`} className="w-2/5">
              <img
                src={article.image}
                alt=""
                className={`${
                  location.pathname.includes("article/")
                    ? "xl:aspect-[5/4]"
                    : "xl:aspect-video"
                } rounded-lg object-cover aspect-square md:aspect-[4/3]`}
              />
            </Link>
            <div className="w-3/5 ml-1.5 flex flex-col justify-around md:ml-3 xl:ml-4">
              <Link to={`/article/${article.id}`}>
                <h2 className="text-xs leading-3 sm:text-base md:leading-5 xl:text-lg gold-hover">
                  {article.title}
                </h2>
              </Link>
              <p className="hidden text-xxs serif mb-1 leading-3 sans-serif lg:block">
                {article.excerpt}
              </p>
              <div className="flex flex-wrap justify-between">
                <p className="text-xxs sans-serif mr-3 leading-3">
                  {article.date.toLocaleDateString("en-us", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  })}
                </p>
                <p className="text-xxs sans-serif leading-3">
                  <Link
                    to={`/author/${article.authorId}`}
                    className="gold-hover"
                  >
                    {article.author}
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
