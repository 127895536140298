import FormatQuoteIcon from "@mui/icons-material/FormatQuote";

export default function About() {
  return (
    <div className="article-wrapper">
      <div className="mx-3 *:mb-2">
        <h1>About</h1>
        <p>
          The first question of the Westminster Shorter Catechism asks the
          following:
        </p>
        <p>
          <span className="sans-serif font-bold">Question:</span> What is the
          chief end (or greatest purpose) of man?
        </p>
        <p>
          <span className="sans-serif font-bold">Answer:</span> The chief end of
          man is to glorify God and enjoy Him forever.
        </p>
        <p>
          The purpose of our lives as Christians is to grow in our relationship
          with God. God decided that the method of revealing Himself to us is
          primarily through the Bible. Therefore, as Christians, to know how to
          glorify God and enjoy Him is to grow in our understanding of the
          Bible.
        </p>
        <div className="bg-white text-black p-3 pt-10 rounded-lg relative sm:p-5 sm:pt-10 lg:p-8 lg:pt-14">
                <span className="absolute text-xl left-1 top-1 sm:left-3 sm:top-3 lg:left-6 lg:top-6">
                  <FormatQuoteIcon fontSize="large" />
                </span>
                <p className="font-black sans-serif text-md my-1 lg:text-lg">
                  So, whether you eat or drink, or whatever you do, do all to
                  the glory of God.
                </p>
                <p className="font sans-serif text-[0.6rem] lg:text-sm xl:mt-2">
                  1 Corinthians 10:31 (English Standard Version)
                </p>
              </div>
        <p>
          The Bible is not exhaustive. The Bible doesn't teach you how to file
          taxes in Canada in the year 2024. The Bible also doesn't mention
          videogames (which is a given because they didn't exist 2000 years
          ago). However, the Bible is sufficient. The Bible has everything we
          need to grow in our faith.
        </p>
        <p>
          One might think that the Bible doesn't inform our understanding of
          videogames. But by taking a closer look, we can see that there are
          many ways that Biblical principles can be applied to gaming.
        </p>
        <p>
          The main goal of this next year is to develop a theology of gaming
          that can be used as a resource to help grow Christian gamers in their
          faith. We want this resource to be accessible in two ways:
        </p>
        <ol className="ml-3">
          <li className="mb-1">
            <p>
              1. Practically accessible meaning free and easy to find. We
              believe that there is such a need for spiritual investment with
              Christian gamers that it should be free. We also want this to be a
              resource that Christians will be able to find with just a Google
              search.
            </p>
          </li>
          <li>
            <p>
              2. Spiritually accessible meaning that a Christian in any part of
              their Christian journey will be able to read this resource and be
              able to glean from it.
            </p>
          </li>
        </ol>
        <p>
          Our ultimate goal is to share the hope that we have by the work of
          Jesus Christ on the cross. We hope that you'll grow in your love for
          God.
        </p>
      </div>
    </div>
  );
}
