import { Link } from "react-router-dom";

import logo from "../logo.svg"

export default function Footer() {
  return (
    <footer className="bg-white text-black p-3 mt-3 md:pb-3 md:px-6 md:pt-4">
      <Link to="/" className="text-xl font-black md:text-2xl flex">
        <img src={logo} className="h-5 xl:h-6 my-auto" alt="" />
        <span className="ml-1 md:ml-1.5">TG</span>
      </Link>
      <p className="sans-serif text-xxs leading-3 mb-1 md:pt-1.5 md:mb-2">
        Helping Christians gamers grow in their faith.
      </p>
      <ul className="text-xxs font-semibold">
        <li className="text-xxs">
          <Link
            to="articles"
            className="hover:bg-gold transition-colors duration-300"
          >
            Articles
          </Link>
        </li>
        <li className="text-xxs">
          <Link
            to="about"
            className="hover:bg-gold transition-colors duration-300"
          >
            About
          </Link>
        </li>
        <li className="text-xxs">
          <Link
            to="support"
            className="hover:bg-gold transition-colors duration-300"
          >
            Support
          </Link>
        </li>
        <li className="text-xxs">
          <Link
            to="privacy-policy"
            className="hover:bg-gold transition-colors duration-300"
          >
            Privacy Policy
          </Link>
        </li>
      </ul>
    </footer>
  );
}
