import { Link } from "react-router-dom";
import { articles } from "../collections/Articles";
import RecentArticles from "../components/RecentArticles";

export default function Articles() {
  return (
    <div className="wrapper">
      <div className="m-2">
        <div className="my-3">
          <div className="lg:flex flex-1">
            <Link to={`/article/${articles[0].id}`} className="lg:w-1/2">
              <img
                src={articles[0].image}
                className="rounded-lg mb-2 aspect-video object-cover lg:aspect-square xl:aspect-[4/3] xl:mb-0"
                alt=""
              />
            </Link>
            <div className="lg:w-1/2 lg:ml-4 xl:flex xl:flex-col xl:justify-between">
              <div>
                <p className="sans-serif text-gold text-xs">Newest Article</p>
                <Link to={`/article/${articles[0].id}`}>
                  <h2 className="gold-hover">{articles[0].title}</h2>
                </Link>
                <p className="text-xxs serif mb-1 leading-3 sans-serif sm:my-2">
                  {articles[0].excerpt}
                </p>
              </div>
              <div className="flex justify-between">
                <p className="text-xxs sans-serif">
                  {articles[0].date.toLocaleDateString("en-us", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  })}
                </p>
                <p className="text-xxs sans-serif">
                  <Link to={`/author/${articles[0].authorId}`} className="gold-hover">
                    {articles[0].author}
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
        <h2 className="mt-4">Recent Articles</h2>
        <RecentArticles
          articles={articles.filter((article, idx) => idx !== 0)}
        />
      </div>
    </div>
  );
}
