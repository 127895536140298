import { articles } from "../collections/Articles";

import RecentArticles from "../components/RecentArticles";

export default function NotFound() {
  return (
    <div className="wrapper">
      <div className="mx-3 mt-3">
        <h1>Page Not Found</h1>
        <p className="my-2 sans-serif">
          Maybe take a look at these articles though 👀👀
        </p>
        <h2 className="mt-3 md:mt-6">Recent Articles</h2>
        <RecentArticles articles={articles} />
      </div>
    </div>
  );
}
