import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import { ArticleType, articles } from "../collections/Articles";
import RecentArticles from "../components/RecentArticles";

const youTubeVideo = (url: string, title: string) => {
  return (
    <div className="aspect-video mb-2">
      <iframe
        title={title}
        width="100%"
        height="100%"
        src={url}
      />
    </div>
  );
};

export default function Article() {
  const params = useParams();
  const navigate = useNavigate();

  const [article, setArticle] = useState<ArticleType>(articles[0]);

  const recentArticles = articles.filter((a) => article.id !== a.id);

  useEffect(() => {
    if (params.article !== undefined) {
      const articleId = params.article;
      const temp = articles.find((a) => a.id === articleId);
      if (temp !== undefined) setArticle(temp);
      else navigate("/page-not-found");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.article]);

  return (
    <div className="article-wrapper">
      <div className="mx-3 mt-2">
        {article.youtubeUrl ? (
          youTubeVideo(article.youtubeUrl, article.title)
        ) : (
          <img src={article.image} className="rounded-lg mb-2" alt="" />
        )}
        <h1 className="md:mt-3 md:mb-1">{article.title}</h1>
        <div className="flex justify-between mb-1 md:mb-2">
          <p className="text-xxs sans-serif">
            {article.date.toLocaleDateString("en-us", {
              year: "numeric",
              month: "long",
              day: "numeric",
            })}
          </p>
          <p className="text-xxs sans-serif">
            <Link to={`/author/${article.authorId}`} className="gold-hover">
              {article.author}
            </Link>
          </p>
        </div>
        <div className="*:mb-2">{article.content}</div>
        {recentArticles.length > 0 ? (
          <>
            <h1 className="mt-3">Recent Articles</h1>
            <RecentArticles articles={recentArticles} />
          </>
        ) : null}
      </div>
    </div>
  );
}
