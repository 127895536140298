import { Routes, Route, useLocation } from "react-router-dom";

import Home from "./pages/Home";
import Progress from "./pages/Progress";
import Update from "./pages/Update";
import Articles from "./pages/Articles";
import Article from "./pages/Article";
import Author from "./pages/Author";
import About from "./pages/About";
import Support from "./pages/Support";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import NotFound from "./pages/NotFound";

import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import { useLayoutEffect } from "react";

type WrapperType = {
  children: React.ReactElement;
};

const Wrapper = ({ children }: WrapperType) => {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return children;
};

function App() {
  return (
    <div className="min-h-screen flex flex-col justify-between align-middle">
      <div>
        <Navbar />
        <Wrapper>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/progress" element={<Progress />} />
            <Route path="/update/:update" element={<Update />} />
            <Route path="/articles" element={<Articles />} />
            <Route path="/article/:article" element={<Article />} />
            <Route path="/author/:author" element={<Author />} />
            <Route path="/about" element={<About />} />
            <Route path="/support" element={<Support />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Wrapper>
      </div>
      <Footer />
    </div>
  );
}

export default App;
