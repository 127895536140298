import { Link } from "react-router-dom";
import shouldChristiansPlayVideogames from "../images/should-christians-play-videogames.png";
import whyTheChurchshouldCareAboutGaming from "../images/why-the-church-should-care-about-gaming.png"

export type ArticleType = {
  id: string;
  title: string;
  authorId: string;
  author: string;
  date: Date;
  editDate?: Date;
  excerpt: string;
  image: string;
  youtubeUrl?: string;
  content: JSX.Element;
};

export const articles: ArticleType[] = [
  {
    id: "why-the-church-should-care-about-gaming",
    title: "Why the Church Should Care About Gaming",
    excerpt:
      "Gaming/Internet culture is so prevalent that the church has to try to understand it.",
    authorId: "maple",
    author: "Maple",
    date: new Date("August 27, 2024"),
    image: whyTheChurchshouldCareAboutGaming,
    youtubeUrl: "https://www.youtube.com/embed/0fE5aa0qssI?si=UItu0Le-OSm6-NLz",
    content: (
      <>
        <p>
          If you were to ask me who should care about developing a theology of
          gaming when I started on this journey back in 2018 up until this past
          June, I would have said that it was only relevant for Christian
          gamers. I was under the belief that even if I didn't do work in this
          area, Christians would eventually come around to understanding
          internet and gaming culture and their impact on the church. However,
          through recent research, I've come to the sobering conclusion that the
          church hasn't made any progress in this area. It's actually gotten
          worse.
        </p>
        <p>
          I believe that the church should care about gaming because gaming
          culture has a massive influence on today's broader culture. If we, as
          the church, can't parse gaming culture through the lens of the gospel,
          it can negatively affect our current and upcoming generations in the
          church. In this past month alone, there have been three cases of major
          figures in the internet/gaming community who have been exposed as
          predators toward children. A big reason why the church should care
          about gaming is for the safety of the children in our congregations.
        </p>
        <p>
          The issue is much more complex than simply banning our children from
          going on the internet. The internet has a much wider reach than the
          physical screens that we use. We'll need to take a look at gaming as a
          culture.
        </p>
        <h2>Gaming Ministry is Cross-Cultural Ministry</h2>
        <p>
          During my undergraduate studies, I spent several years working with
          international students. Nearing the end of undergrad, I stopped
          working with international students so that I could organize and run
          videogame tournaments. What I realized is that the same principles
          that we use to understand and appreciate other cultures are the same
          principles that we should use to understand and appreciating the
          gaming community and gamers.
        </p>
        <p>
          The Merriam-Webster dictionary defines{" "}
          <Link
            className="link serif"
            to="https://www.merriam-webster.com/dictionary/culture"
          >
            culture
          </Link>{" "}
          as “the customary beliefs, social forms, and material traits of a
          racial, religious, or social group.”
        </p>
        <p>
          When we, as the church, think about cross-cultural ministry, our scope
          usually is limited to ministry that involves one ethnic group
          ministering to another or one country ministering to another. I'd
          argue that the scope of cross-cultural ministry should be expanded
          beyond these things. This is why I'd argue that ministry to the gaming
          community is cross-cultural ministry.
        </p>
        <h2>The Development of Gaming as a Culture</h2>
        <p>
          If we look at gaming over the past half-century, we can divide the
          cultural aspect of gaming into different eras: arcade, home console,
          early internet, and pandemic/post-pandemic.
        </p>
        <p>
          If you wanted to play games during the arcade era, you would have to
          go to an arcade. This was a physical space where you could put a coin
          into an arcade cabinet to play perhaps a fighting game against another
          person at the cabinet across from you. If you were a gamer in this
          era, you'd probably go to an arcade once or twice a week, if not every
          day. If you went consistently, you'd be able to meet new people who
          were also gamers. In this sense, gaming had a communal aspect to it.
        </p>
        <p>
          As home consoles became more financially accessible to the average
          household, gamers didn't need to go to an arcade. This was the era
          that I grew up in. During elementary school, I would ask friends to
          hang out at my house after school to play Super Smash Bros. or Mario
          Kart. On other days, they would ask me to hang out at their house.
          Gaming became less of a communal thing, as hopefully you wouldn't meet
          new people in your home.
        </p>
        <p>
          The internet brought new avenues for gaming culture. In this era, you
          could play games with friends without ever leaving your house. You
          could even meet new friends from different countries. When I think
          about gaming in this era, I think back to the early days of Call of
          Duty lobbies and the amount of trash-talking that definitely wasn't
          appropriate for any child to be a part of.
        </p>
        <p>
          The pandemic brought a completely new era to the cultural aspect of
          gaming, particularly for Zoomers and Gen Alphas. If you were a child
          during the pandemic, how would you hang out with friends after your
          Zoom class? You couldn't do it in person, as there were restrictions
          against meeting physically. Many children socialized by playing Among
          Us Roblox, or Minecraft. Many children's social lives were through
          gaming. I'd argue that if a child was in a class where all their
          classmates played video games to socialize after school and lived in a
          household where gaming was banned, there's a possibility that that
          child could be socially underdeveloped. Even now, as we're several
          years removed from the pandemic, gaming still holds an essential part
          of a lot of children's lives.
        </p>
        <p>
          As a sidenote, gaming culture is not just one culture. Someone who
          grew up in the early days of gaming would be part of an entirely
          different culture than children who grew up gaming during the
          pandemic. This complicates our understanding of gaming as a culture.
          As the internet continues to develop, internet culture becomes more
          overlapped with gaming culture.
        </p>
        <h2>Gaming Culture is More Prevalent Than You Might Think</h2>
        <p>
          People understate the cultural impact that gaming culture has on
          today's broader culture. In a sea of tens of thousands of university
          students at a conference for young adults, Louie Giglio was giving a
          talk about the internet and how it can take our time away from Jesus.{" "}
          <Link
            className="link serif"
            to="https://www.youtube.com/watch?v=FBX01k96vK0&t=1252s"
          >
            In this talk
          </Link>
          , Giglio said the word, “rizz.” He had to pause for a moment because
          of how much the audience was laughing from the fact that an older
          pastor said a Zoomer term.
        </p>
        <p>
          What is rizz? Although the word{" "}
          <Link
            className="link serif"
            to="https://knowyourmeme.com/memes/rizz-unspoken-rizz"
          >
            rizz
          </Link>{" "}
          is derived from the word “charisma,” the meaning of the word leans
          closer to the word “game,” or one's ability to romantically attract
          others. The word originated on a stream from{" "}
          <Link
            className="link serif"
            to="https://en.wikipedia.org/wiki/Kai_Cenat"
          >
            Kai Cenat
          </Link>
          , a Twitch streamer with over 10 million followers. What is{" "}
          <Link
            className="link serif"
            to="https://en.wikipedia.org/wiki/Twitch_(service)"
          >
            Twitch
          </Link>
          ? Twitch is a streaming website that focuses on videogame streams.
          Therefore, rizz is a word that originated from gaming culture, and
          it's a well-known term at that. The word rizz is so well-known that
          the Oxford English Dictionary made{" "}
          <Link
            className="link serif"
            to="https://languages.oup.com/word-of-the-year/2023/"
          >
            rizz the word of the year in 2023
          </Link>
          .
        </p>
        <p>
          Although this is a fun example of the impact of gaming culture on
          today's culture, we need to realize that gaming culture impacts much
          more than just the words we use. Gaming culture can impact today's
          cultural beliefs, morals, and values.
        </p>
        <h2>Why Should the Church Care About Gaming?</h2>
        <p>
          If you ask a child what they want to be when they grow up, the most
          common answer that you'll get nowadays is YouTuber, streamer, or
          content creator. The content creation space is oversaturated with
          people who want to become famous online. As opposed to 10-15 years
          ago, content creators need to put in more time and effort than their
          peers if they want to make it big. If you're putting in 10 hours a
          week into making videos, somebody out there is putting in 20 hours a
          week. I believe that children who will make it big in a decade will
          have to sacrifice something in order to have enough time to invest in
          creating content, and I believe that a lot of children will end up
          sacrificing their social lives.
        </p>
        <p>
          The three cases of people getting exposed as child predators are
          examples of people who made it big during the earlier days of the
          internet. I'm for the most part a hopeful person, but I believe that
          we'll see more of these cases in the future, especially when
          considering the fact that many up-and-coming influencers will be
          socially underdeveloped. Why should the church care about gaming? We
          should care not just to keep our children safe, but so that they can
          thrive in their faith in the coming years.
        </p>
        <h2>What Should We Do?</h2>
        <p>
          As a church, our understanding of internet/gaming culture will grow
          over time, but we're currently living in an era where everything is
          novel. The lack of materials that we have to understand all this is a
          call to action for personal spiritual growth. If you grew up as a
          gamer, you've probably had no one guide you through the dangers of the
          internet. This doesn't have to be the case for the children in our
          congregation. God cares about children, and we should grow in our
          understanding of internet/gaming culture to better care for them as
          well.
        </p>
      </>
    ),
  },
  {
    id: "should-christians-play-videogames",
    title: "Should Christians Play Videogames?",
    excerpt:
      "Answering the most asked question regarding Christianity and gaming.",
    authorId: "maple",
    author: "Maple",
    date: new Date("August 7, 2024"),
    image: shouldChristiansPlayVideogames,
    youtubeUrl: "https://www.youtube.com/embed/zVMxEH_DT24?si=sW6Hf29q6wn0d7H0",
    content: (
      <>
        <p>
          Whenever you search for Christianity and gaming online, the discussion
          question that usually comes up is, “Should Christians play
          videogames?” In these discussions, the answer is usually something
          along the lines of time and priority.
        </p>
        <p>
          "If videogames take up an unhealthy amount of time, then it's a sin.
          If not, then it can be good."
        </p>
        <p>
          "If videogames take the place of an idol in your life, then it's a
          sin. If not, then it can be good."
        </p>
        <p>
          For the most part, I agree with these answers. However, you can really
          apply these two points to any topic: whether it be work or rest, or
          other mediums such as watching movies or playing sports. My issue with
          the discussion isn't the answers, but rather the question itself. It's
          a vague question that doesn't allow us to explore the topic of
          videogames on a deeper level. In this article, we're going to explore
          what a better question would be and answer that.
        </p>

        <h2>Arriving at a Better Question</h2>
        <p>
          The first thing to note with the question, “Should Christians play
          videogames?” is that it's incomplete. Should Christians play
          videogames . . . to what? Should Christians play videogames to grow in
          their faith? Well, the answer to that is no! I don't believe that a
          Christian living in the first century in the early-church would be
          deprived of growing in their faith in certain ways because they didn't
          have access to videogames.
        </p>
        <p>
          Another issue with the question is that it's a close-ended question.
          This question doesn't invite us to explore the topic deeper outside of
          justifying our answer of yes or no.
        </p>
        <p>
          We're going to change the question to this instead: “Can Christians
          use videogames to glorify God?” Modifying the question in this way
          allows us to explore how we can use videogames as a tool to worship
          God. This question also shifts our focus away from viewing the
          Christian life as permissible and impermissible towards viewing the
          Christian life as a relationship with God.
        </p>

        <h2>Purpose</h2>
        <p>
          During university, I bought a Nintendo Switch for the sole purpose of
          playing Smash Ultimate. I wanted to get good at this game at a
          competitive level. I put in a lot of time practicing tech skill and
          dissecting how pro gamers played at majors.
        </p>
        <p>
          I ended up winning a one-off tournament that someone on campus
          organized. Competing was more exhilarating than I thought, and I got
          to experience meeting new people who I otherwise never would have
          interacted with. Because of this experience, I decided to continue
          running tournaments bi-weekly. I noticed, at the bi-weeklies, I would
          see the same faces every time. People who would come to one tournament
          would come to all of them. Some would stay after the tournament to get
          some reps in through friendly matches. They would also hang out
          outside of tournaments to play or to just hang out with their newfound
          friends. Something more than just playing Smash Ultimate was going on.
          A community was being built. I asked these people if they wanted to
          get breakfast together on Saturday mornings to share our lives with
          each other, and of course they agreed.
        </p>
        <p>
          When examining the question, “Can Christians use videogames to glorify
          God?” it's important to note the word use. Videogames, in the context
          of this question, are simply the tool that we use by which we glorify
          God.
        </p>
        <p>
          I played Smash Ultimate for a self-motivated reason. However, because
          I had a relationship with God, He changed my purpose behind gaming. He
          used Smash as a tool to grow a community and to develop real
          relationships. Do I believe that I used videogames to glorify God in
          this example? I do. The reason why is because my motives became
          God-honoring. However, this example is specific to me and why I
          personally game. The answer will apply itself differently to different
          people because not every Christian uses videogames to build a
          community.
        </p>
        <p>
          There are good and bad motivations behind why a Christian would game:
          to rest, to compete, to enjoy stories, to socialize, to find meaning
          in life, or to escape from life would be a few examples. Although the
          Bible does not explicitly mention videogames at all, the Bible does
          mention all of these. To glorify God by using videogames, one would
          have to develop a Christian understanding to why they game in the
          first place. This is why I'd argue that a theology of videogames is
          both systematic theology and applicational theology.
        </p>
        <p>
          We'll do a deep dive into these in the coming years, but I hope that
          my personal experience can be used to help answer this question. If
          you were to ask me: “Can you use videogames as a tool to glorify God?”
          I would answer with a resounding “Yes!”
        </p>
      </>
    ),
  },
];
