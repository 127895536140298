import { Link } from "react-router-dom";
import mapleImage from "../images/authors/maple.png";

import meleeCover from "../images/games/melee.png";
import ultimateCover from "../images/games/ultimate.png";
import minecraftCover from "../images/games/minecraft.png";
import leagueCover from "../images/games/league-of-legends.png";
import slayTheSpireCover from "../images/games/slay-the-spire.png";

type Game = {
  title: string;
  cover: string;
};

export type AuthorType = {
  id: string;
  name: string;
  image: string;
  bio: JSX.Element;
  games: Game[];
};

export const authors: AuthorType[] = [
  {
    id: "maple",
    name: "Maple",
    image: mapleImage,
    bio: (
      <>
        <p className="sans-serif">
          Hi! I go by the name Maple. I majored in Christian counseling with a
          concentration in apologetics in undergrad. I'm currently a deacon and
          leader at my church. I'm saving up money to go to seminary; I want to
          learn how to read the Bible in its original languages.
        </p>
        <p className="sans-serif">
          The articles that I write are based off of the videos that I make on{" "}
          <Link
            className="link"
            to="https://www.youtube.com/@maplethechristian"
          >
            YouTube
          </Link>
          . I'm also posting the update videos for this ministry on there!
        </p>
      </>
    ),
    games: [
      { title: "Super Smash Bros. Ultimate", cover: ultimateCover },
      { title: "Minecraft", cover: minecraftCover },
      { title: "Super Smash Bros. Melee", cover: meleeCover },
      { title: "League of Legends", cover: leagueCover },
      { title: "Slay the Spire", cover: slayTheSpireCover },
    ],
  },
];
