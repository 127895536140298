import { Link } from "react-router-dom";

export default function Support() {
  return (
    <div className="article-wrapper">
      <div className="mx-3 *:mb-2">
        <h1>Support</h1>
        <p>
          As stated in the About page, the main goal of this ministry is to
          develop a theology of gaming that can be used as a resource to help
          grow Christian gamers in their faith. We want this resource to be
          accessible in two ways:
        </p>
        <ol className="ml-3">
          <li className="mb-1">
            <p>
              1. Practically accessible meaning free and easy to find. We
              believe that there is such a need for spiritual investment with
              Christian gamers that it should be free. We also want this to be a
              resource that Christians will be able to find with just a Google
              search.
            </p>
          </li>
          <li>
            <p>
              2. Spiritually accessible meaning that a Christian in any part of
              their Christian journey will be able to read this resource and be
              able to glean from it.
            </p>
          </li>
        </ol>
        <p>
          The best way to support this ministry is through prayer. We can use
          all the prayer support we can get.
        </p>
        <p>
          If you want to join our journey of creating a free online course for a
          theology of gaming, feel free to subscribe to{" "}
          <Link
            className="serif link"
            to="https://www.youtube.com/@maplethechristian"
          >
            Maple
          </Link>{" "}
          on YouTube. Maple will be posting update videos.
        </p>
        <p>
          If you want to reach out via email, please do so via
          maplethechristian@gmail.com
        </p>
      </div>
    </div>
  );
}
