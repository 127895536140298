import introducingOurLogo from "../images/introducing-our-logo.png";
import vennDiagram from "../images/venn-diagram.png";
import vennDiagramFish from "../images/venn-diagram-fish.png";
import logo from "../logo.svg";
import logoWhite from "../logo-white.svg";
import logoGold from "../logo-gold.svg";

export type UpdateType = {
  id: string;
  title: string;
  date: Date;
  editDate?: Date;
  image: string;
  youtubeUrl?: string;
  content: JSX.Element;
};

export const updates: UpdateType[] = [
  {
    id: "introducing-our-logo",
    title: "Introducing Our Logo",
    date: new Date("September 12, 2024"),
    image: introducingOurLogo,
    // youtubeUrl: "https://www.youtube.com/embed/0fE5aa0qssI?si=UItu0Le-OSm6-NLz",
    content: (
      <>
        <p>
          A logo means a lot to a brand. It's a part of a brand's identity and
          communicates its values. I've created logos before in past attempts of
          starting a video game ministry. Thankfully, that experience carried
          over into the creation of our logo at Theology of Gaming.
        </p>
        <p>
          Most of the work on our logo was done during my day job, where I
          multitasked between completing menial tasks and visualizing different
          variations of what I envisioned for the logo. Initially, I wanted to
          create a logo that wasn't directly tied to our mission at Theology of
          Gaming but instead reflected my personal values. I envisioned a logo
          with two fish, referencing the boy who gave his two fish and five
          loaves to Jesus, which He used to feed the five thousand. This story
          has been on my mind lately. What stands out to me is how Jesus
          multiplied what's considered a small, insignificant offering. In
          creating this ministry, I've come to relate to that boy. The need for
          this ministry is immense, yet I see myself as unqualified for tacking
          such a need. However, I believe it's God who will use my small
          offering to accomplish what needs to be done.
        </p>
        <p>
          In the evening, after getting home from work, I began working on the
          logo. I copied an image of an Ichthys and used Inkscape, a free vector
          graphics editor, to create variations of two fish. Eventually, I
          stumbled upon a design where one fish overlapped the other, forming
          something like a Venn diagram. That's when the idea struck me—it was
          perfect. A Venn diagram represents our mission at Theology of Gaming:
          bringing our understanding of gaming under the framework of the
          Christian faith. I've used Venn diagrams before to illustrate this
          concept. And there was more—the overlapping section of the two fish
          resembled a fish itself!
        </p>
        <div className="flex">
          <img
            src={vennDiagram}
            className="w-1/2 mr-2 rounded-lg object-cover"
            alt=""
          />
          <img
            src={vennDiagramFish}
            className="w-1/2 rounded-lg object-cover"
            alt="venn diagram with a fish eye in the middle"
          />
        </div>
        <p>
          I took a screenshot for reference and started again from scratch. I
          replaced the two fish with two circles, allowing the Ichthys to appear
          in the negative space of the Venn diagram. I added an eye to make it
          clear that there was indeed a fish in the overlapping section.
          Finally, I rotated the image 45 degrees because logos nowadays need to
          fit within a square aspect ratio (for app icons, favicons, desktop
          icons, and so on).
        </p>
        <p>Without further ado, here's our logo.</p>
        <div className="flex">
          <img
            src={logoGold}
            className="w-1/3 mr-2 rounded-lg object-cover bg-neutral-950 p-4"
            alt=""
          />
          <img
            src={logo}
            className="w-1/3 mr-2 rounded-lg object-cover bg-white p-4"
            alt=""
          />
          <img
            src={logoWhite}
            className="w-1/3 rounded-lg object-cover bg-neutral-950 p-4"
            alt=""
          />
        </div>
        <p>
          I'm really happy with the result and it was a fun project to do.
        </p>
      </>
    ),
  },
];
