import { motion } from "framer-motion";

const container = {
  initial: { opacity: 1, scale: 1 },
  animate: {
    transition: {
      delayChildren: 0,
      staggerChildren: 0.3,
    },
  },
};

const item = {
  initial: { y: 10, opacity: 0 },
  animate: {
    y: 0,
    opacity: 1,
  },
};

export default function MotionVariants(props: {
  elements: JSX.Element[];
  styles?: String;
  viewportAmount: number | "all" | "some" | undefined;
  viewportOnce: boolean
}) {
  const { elements, styles, viewportAmount, viewportOnce } = props;

  return (
    <motion.ul
      className={`container ${styles}`}
      variants={container}
      initial="initial"
      whileInView="animate"
      viewport={{ once: viewportOnce, amount: viewportAmount }}
    >
      {elements.map((element, index) => (
        <motion.li key={index} className="item" variants={item}>
          {element}
        </motion.li>
      ))}
    </motion.ul>
  );
}

MotionVariants.defaultProps = {
  viewportOnce: true,
  viewportAmount: "all",
};
