import { Link } from "react-router-dom";
import { useState } from "react";
import MotionVariants from "./MotionVariants";

import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import logo from "../logo-white.svg";

export default function Navbar() {
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const [hidden, setHidden] = useState<boolean>(true);

  const toggleMenu = () => {
    if (hidden) {
      setHidden(false);
      setTimeout(() => {
        setShowMenu(true);
      }, 1);
    } else {
      setShowMenu(false);
      setTimeout(() => {
        setHidden(true);
      }, 200);
    }
  };

  return (
    <div className="">
      <nav className="flex justify-between mx-3 mt-3 md:mb-3 md:mx-6 md:mt-4 lg:mb-6">
        <div>
          <Link to="/" className="text-xl font-black md:text-2xl flex">
            <img src={logo} className="h-5 xl:h-6 my-auto" alt="" />
            <span className="ml-1 md:ml-1.5 lg:ml-2">TG</span>
          </Link>
        </div>
        <button onClick={toggleMenu} className="lg:hidden">
          <MenuIcon />
        </button>
        {/* Desktop Menu */}
        <div className="hidden lg:block my-auto">
          <ul className="flex font-semibold my-auto">
            <p className="mr-3">
              <Link to="progress" className="gold-hover">
                Progress
              </Link>
            </p>
            <p className="mr-3">
              <Link to="articles" className="gold-hover">
                Articles
              </Link>
            </p>
            <p className="mr-3">
              <Link to="about" className="gold-hover">
                About
              </Link>
            </p>
            <p className="mr-3">
              <Link to="support" className="gold-hover">
                Support
              </Link>
            </p>
          </ul>
        </div>
      </nav>
      {/* Mobile Menu */}
      <div
        className={`${showMenu ? "opacity-100" : "opacity-0"} ${
          hidden ? "hidden" : ""
        } w-screen h-screen z-10 fixed inset-0 bg-neutral-900 transition-all duration-200`}
      >
        <div>
          <button onClick={toggleMenu} className="fixed right-2 top-2">
            <CloseIcon />
          </button>
          <ul className="font-semibold text-center mx-auto">
            <MotionVariants
              elements={[
                <div>
                  <img src={logo} className="mt-8 mx-auto h-16" alt="" />
                  <h1 className="mt-4 mx-2 leading-6">
                    <Link to="/" onClick={toggleMenu}>
                      Theology of Gaming
                    </Link>
                  </h1>
                  <hr className="my-3 w-3/4 mx-auto" />
                </div>,
                <div className="mx-auto">
                  <p className="mb-1">
                    <Link to="progress" onClick={toggleMenu}>
                      Progress
                    </Link>
                  </p>
                  <p className="mb-1">
                    <Link to="articles" onClick={toggleMenu}>
                      Articles
                    </Link>
                  </p>
                  <p className="mb-1" onClick={toggleMenu}>
                    <Link to="about">About</Link>
                  </p>
                  <p className="mb-1" onClick={toggleMenu}>
                    <Link to="support">Support</Link>
                  </p>
                </div>,
              ]}
              styles={"mx-auto"}
              viewportOnce={false}
            />
          </ul>
        </div>
      </div>
    </div>
  );
}
