import { updates } from "../collections/Updates";
import RecentUpdates from "../components/RecentUpdates";

export default function Progress() {
  return (
    <div className="wrapper">
      <div className="m-2">
        <h1 className="mt-4">Recent Updates</h1>
        <RecentUpdates updates={updates} />
      </div>
    </div>
  );
}
