export default function PrivacyPolicy() {
  return (
    <div className="article-wrapper">
      <div className="mx-3 *:mb-2">
        <h1>Privacy Policy</h1>
        <p>
          <strong>Effective Date:</strong> August 1, 2024
        </p>
        <p>
          Welcome to Theology of Gaming. Your privacy is important to us. This
          Privacy Policy explains how we collect, use, disclose, and protect your
          information when you visit our website theologyofgaming.com.
        </p>
  
        <h2>1. Information We Collect</h2>
        <p>
          <strong>Personal Information:</strong> When you sign up for our
          newsletter or contact us, we may collect personal information such as
          your name, email address, and any other information you choose to
          provide.
        </p>
        <p>
          <strong>Log Data:</strong> Our servers automatically record information
          including your IP address, browser type, referring/exit pages, and the
          date and time of your visit.
        </p>
        <p>
          <strong>Cookies and Tracking Technologies:</strong> We use cookies and
          similar technologies to track the activity on our Site and hold certain
          information. Cookies are files with a small amount of data which may
          include an anonymous unique identifier.
        </p>
  
        <h2>2. How We Use Your Information</h2>
        <p>To provide, maintain, and improve our Site.</p>
        <p>To send you newsletters, updates, and other communications.</p>
        <p>To respond to your requests.</p>
        <p>To monitor and analyze usage and trends to improve your experience.</p>
        <p>To personalize and enhance your experience on our Site.</p>
        <p>
          To protect, investigate, and deter against fraudulent, unauthorized, or
          illegal activity.
        </p>
  
        <h2>3. Sharing Your Information</h2>
        <p>
          We do not sell, trade, or otherwise transfer to outside parties your
          Personally Identifiable Information unless we provide users with advance
          notice. This does not include website hosting partners and other parties
          who assist us in operating our website, conducting our business, or
          serving our users, so long as those parties agree to keep this
          information confidential. We may also release information when it's
          release is appropriate to comply with the law, enforce our site
          policies, or protect ours or others' rights, property or safety.
        </p>
  
        <h2>4. Security of Your Information</h2>
        <p>
          We use administrative, technical, and physical security measures to help
          protect your personal information. While we have taken reasonable steps
          to secure the personal information you provide to us, please be aware
          that despite our efforts, no security measures are perfect or
          impenetrable, and no method of data transmission can be guaranteed
          against any interception or other type of misuse.
        </p>
  
        <h2>5. Your Privacy Rights</h2>
        <p>
          Depending on your location, you may have the following rights regarding
          your personal information:
        </p>
        <p>
          The right to access - You have the right to request copies of your
          personal information.
        </p>
        <p>
          The right to rectification - You have the right to request that we
          correct any information you believe is inaccurate or complete
          information you believe is incomplete.
        </p>
        <p>
          The right to erasure - You have the right to request that we erase your
          personal information, under certain conditions.
        </p>
        <p>
          The right to restrict processing - You have the right to request that we
          restrict the processing of your personal information, under certain
          conditions.
        </p>
        <p>
          The right to object to processing - You have the right to object to our
          processing of your personal information, under certain conditions.
        </p>
        <p>
          The right to data portability - You have the right to request that we
          transfer the data that we have collected to another organization, or
          directly to you, under certain conditions.
        </p>
        <p>
          If you make a request, we have one month to respond to you. If you would
          like to exercise any of these rights, please contact us at our email:
          maplethechristian@gmail.com
        </p>
  
        <h2>6. Changes to This Privacy Policy</h2>
        <p>
          We may update our Privacy Policy from time to time. We will notify you
          of any changes by posting the new Privacy Policy on this page. You are
          advised to review this Privacy Policy periodically for any changes.
          Changes to this Privacy Policy are effective when they are posted on
          this page.
        </p>
      </div>
    </div>
  );
}
