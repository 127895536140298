import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { UpdateType, updates } from "../collections/Updates";
import RecentUpdates from "../components/RecentUpdates";

const YouTubeVideo = (url: { url: string }) => {
  return (
    <div className="aspect-video mb-2">
      <iframe
        title="Should Christians Play Videogames?"
        width="100%"
        height="100%"
        src={url.url}
      />
    </div>
  );
};

export default function Update() {
  const params = useParams();
  const navigate = useNavigate();

  const [update, setUpdate] = useState<UpdateType>(updates[0]);

  const recentUpdates = updates.filter((u) => update.id !== u.id);

  useEffect(() => {
    if (params.update !== undefined) {
      const updateId = params.update;
      const temp = updates.find((u) => u.id === updateId);
      if (temp !== undefined) setUpdate(temp);
      else navigate("/page-not-found");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.update]);

  return (
    <div className="article-wrapper">
      <div className="mx-3 mt-2">
        {update.youtubeUrl ? (
          <YouTubeVideo url={update.youtubeUrl} />
        ) : (
          <img src={update.image} className="rounded-lg mb-2" alt="" />
        )}
        <h1 className="md:mt-3 md:mb-1">{update.title}</h1>
        <p className="text-xxs sans-serif mb-2">
          {update.date.toLocaleDateString("en-us", {
            year: "numeric",
            month: "long",
            day: "numeric",
          })}
        </p>
        <div className="*:mb-2">{update.content}</div>
        {recentUpdates.length > 0 ? (
          <>
            <h1 className="mt-3">Recent Updates</h1>
            <RecentUpdates updates={recentUpdates} />
          </>
        ) : null}
      </div>
    </div>
  );
}
