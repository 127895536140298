import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { motion } from "framer-motion";

import { AuthorType, authors } from "../collections/Authors";

export default function Author() {
  const params = useParams();
  const navigate = useNavigate();

  const [author, setAuthor] = useState<AuthorType>(authors[0]);

  useEffect(() => {
    if (params.author !== undefined) {
      const authorId = params.author;
      const temp = authors.find((a) => a.id === authorId);
      if (temp !== undefined) setAuthor(temp);
      else navigate("/page-not-found");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.author]);

  return (
    <div className="wrapper">
      <div className="mx-3 text-center mt-3 md:text-left">
        <img
          src={author.image}
          alt={author.name}
          className="rounded-full object-cover aspect-square w-1/3 mx-auto max-w-36"
        />
        <h1 className="my-2 text-center">{author.name}</h1>
        <div className="*:mb-2">{author.bio}</div>
        <h2 className="text-center my-3">Favourite Games</h2>
        <div className="flex flex-wrap justify-center *:mr-2 *:mb-2 mx-auto">
          {author.games.map((game, idx) => (
            <motion.img
              whileHover={{ scale: 1.03 }}
              key={idx}
              src={game.cover}
              className="rounded"
              alt={`Game cover for ${game.title}`}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
